import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import Popup from 'reactjs-popup';
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import disperse from "../contracts/Disperse.json";
import ERC20 from "../contracts/erc20.json";
import { DISPERSEABI } from "../assets/disperse";
import {
  Form,
  TextArea,
  Input,
  Button,
  Grid,
  Divider,
  Header
} from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css'
import {
  weeklyPerToker,
  totalRewards,
  totalWeeklyReward,
  tokerRatio,
} from "../dataConfig/data.json";

export const StyledButton = styled.button`
  float: ${({ fl }) => (fl ? fl : "right")};
  padding: 10px;
  font-size: 11px;
  text-align: ${({ txa }) => (txa ? txa : "center")};
  margin-left: ${({ ml }) => (ml ? ml : "0px")};
  margin-right: ${({ mr }) => (mr ? mr : "0px")};
  border-radius: ${({ br }) => (br ? br : "50px")};
  border: none;
  background-color: ${({ bg }) => (bg ? bg : "#ffffff")};;
  padding: 10px;
  font-weight: bold;
  font-size:${({ fs }) => (fs ? fs : "0px")};
  color: #000000;
  width:  ${({ w }) => (w ? w : "100px")};
  cursor: ${({ crs }) => (crs ? crs : "")};
  z-index:44;
  position: "absolute";
  box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
    : active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
`;

const UNIT = 1000000000000000000;
const address = "0xfCB4D494013CEa160A447a09230dB84425B2cB04";
let url = 'https://polygonscan.com/tx/0x598cd72f4bfa5b277fd134624c76b2466603d370f98b6320ce84de0f9e83d476/'

function Rewards() {
  const [textValue, setTextValue] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [disableDisperse, setDisableDisperse] = useState(true);
  const [disableApprove, setDisableApprove] = useState(false);
  const [addressArray, setAddressArray] = useState([]);
  const [amountArray, setAmountArray] = useState([]);
// 
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
//
  const ph = // (placeholder text)
  "(Toker Wallet Address) (Reward)\n" +
  "0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a 20\n" +
  "0x141ca95b6177615fb1417cf70e930e102bf8f584 25";

const onButtonClick = () => {
dispatch(connect());
};

const handleChange = (e) => {
setTextValue(e.target.value);
};

const handleInput = (e) => {
setTokenAddress(e.target.value);
};

const handleTokenCalls = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
};

const handleApprove = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
  let tempArray = textValue.valueOf().split(/[\s,;:\t\r\n]+/);

  let oddArray = tempArray.filter((v, i) => i % 2);
  let evenArray = tempArray.filter((v, i) => !(i % 2));

  let addArray = [];
  let amtArray = [];
  let total = 0.0;

  for (let i = 0; i < evenArray.length; ++i) {
    if (!isNaN(oddArray[i]) && parseFloat(oddArray[i]) > 0.0) {
      addArray.push(evenArray[i].trim());
      amtArray.push("0x" + (oddArray[i] * UNIT).toString(16));
      total += parseFloat(oddArray[i]);
    }
  }

  setAddressArray(addArray);
  setAmountArray(amtArray);

  const erc20Contract = new ethers.Contract(tokenAddress, ERC20, provider);
  const erc20Signed = erc20Contract.connect(signer);

  console.log(total);


  let allowance = new BigNumber(total);

  allowance = allowance.multipliedBy(UNIT);
  console.log(allowance.toString());
  console.log(tokenAddress);
  console.log(addArray);
  console.log(amtArray);

  const toHex = "0x" + allowance.toString(16);

  const success = await erc20Signed.approve(address, toHex);

  setDisableDisperse(false);

  //let tx = await disperseSigned.disperseToken(tokenAddress,addArray,amtArray);
};

const handleDisperse = async () => {
  setDisableApprove(true);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

  const disperseContract = new ethers.Contract(
    address,
    disperse.abi,
    provider
  );
  const disperseSigned = disperseContract.connect(signer);

  if (addressArray.length === amountArray.length && addressArray.length > 0) {
    let tx = await disperseSigned.tokerRewards(
      tokenAddress,
      addressArray,
      amountArray
    );
  } else {
    alert("Please enter at least one valid transaction");
  }

  setAddressArray([]);
  setAmountArray([]);
  setDisableApprove(false);
  setDisableDisperse(true);
};

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [blockchain.account]);

  const connectFailed = (payload) => {
    return {
      type: "CONNECTION_FAILED",
      payload: payload,
    };
  };

  useEffect(() => {
    if (blockchain.errorMsg !== "") {
      toast.error(blockchain.errorMsg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(connectFailed(""));
    }
    // eslint-disable-next-line 
  }, [blockchain.errorMsg]);
  // eslint-disable-next-line


  if (blockchain.account) {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}></Grid.Column>
          <Grid.Column width={10}>
            <Form>
              <Form.Field>
                <Header as="h1">
                  <center>
                  <br />
                  <br />
                  <Header.Content>The Midnight Tokers Club <br /></Header.Content>
                  </center>
                </Header>
                <Header as="h2">
                  <center>
                  <Header.Content>#TokerRewards <br /></Header.Content>
                  </center>
                </Header>
                  <br />
               

        
        <Header as="h2">
                  <center>
                  <br />
                  <br />
                  <Header.Content>Disperse TokerRewards</Header.Content>
                  </center>
                </Header>
                <Divider />
                <p>ERC20 Token Address</p>
                
              </Form.Field>
              <Form.Field>
                <Input
                  focus
                  placeholder="Enter ERC20 Contract Address"
                  onChange={handleInput}
                />
              </Form.Field>
              <Divider />
              <Form.Field>
                <p>
                  List of Wallets & Rewards
                </p>
              </Form.Field>
             
              <Form.Field>
                <TextArea focus placeholder={ph} onChange={handleChange} />
              </Form.Field>
              <Divider />
              Approve QTY to contract, & Disperse.
              <Divider />
              <Form.Field>
                <Button
                  basic
                  color="green"
                  onClick={async () => handleApprove()}
                  disabled={disableApprove}
                >
                  Approve
                </Button>
                <Button
                  basic
                  color="green"
                  onClick={async () => handleDisperse()}
                  disabled={disableDisperse}
                >
                  Disperse
                </Button>
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={3}></Grid.Column>
        </Grid.Row>
      </Grid>
    );
  } else {
    return (
      <s.Container>
      <Grid>
          <Grid.Column width={0}>
            <Grid.Row>
              {!(blockchain.account) ? (
                <div>
                  <s.Container>
                    Enter to view the most recent TokerRewards
                  </s.Container>
                </div>
              ) : (
                <div></div>
              )}
            </Grid.Row>
            <Grid.Row>
            <s.Container flex={1} ai={"end"} onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}>
                  <StyledButton fs={window.innerWidth > 1500 ? "13px" : "15px"} crs="pointer">Connect</StyledButton>
                </s.Container>
            </Grid.Row>
          </Grid.Column>
      </Grid>
      </s.Container>
    );
              }
            };
export default Rewards;
