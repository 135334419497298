import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import storeRaffle from "./redux/storeRaffle";
import store from "./redux/store";


import { Provider } from "react-redux";
import "./styles/reset.css";
import "./styles/globalStyles"
import Mint from '../src/pages/mint'
import Raffles from '../src/pages/raffles'

ReactDOM.render(
   
   
      <Provider store={store}>
      <App />
      </Provider>
   

  ,
 document.getElementById("root")
);

// MULTIPLE STORES --- V
//    <Provider store={store}>
//    <Mint />
//    <Provider store={storeRaffle}>
//    <Raffles />
//    </Provider>
//    </Provider>

reportWebVitals();
