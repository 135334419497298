import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--dark-grey);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow:hidden
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

export const SpacerXL = styled.div`
  height: 45px;
  width: 24px;
`;

export const SpacerXXL = styled.div`
  height: 160px;
  width: 24px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: ${({ d }) => (d ? d : "flex")};
  padding-bottom: ${({ pb }) => (pb ? pb : "0px")};
  padding-left: ${({ pl }) => (pl ? pl : "0px")};
  padding-top: ${({ pt }) => (pt ? pt : "0px")};
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: center;
 
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const Container1 = styled.div`
  display: ${({ d }) => (d ? d : "flex")};
  padding-bottom: ${({ pb }) => (pb ? pb : "0px")};
  padding-left: ${({ pl }) => (pl ? pl : "0px")};
  padding-top: ${({ pt }) => (pt ? pt : "0px")};
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const Container2 = styled.div`
  display: ${({ d }) => (d ? d : "flex")};
  padding-bottom: ${({ pb }) => (pb ? pb : "30px")};
  padding-left: ${({ pl }) => (pl ? pl : "10px")};
  padding-right: ${({ pl }) => (pl ? pl : "10px")};
  padding-top: ${({ pt }) => (pt ? pt : "30px")};
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  
  justify-content: ${({ jc }) => (jc ? jc : "center")};
  align-items: ${({ ai }) => (ai ? ai : "Center")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: fit-content;
  height: fit-content
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-color: #ffffff34;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--white);
  font-size: 28px;
  font-weight: bold,
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 18px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;