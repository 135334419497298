import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mint from './pages/mint';
import Raffles from './pages/raffles';
import Home from './pages/home';
import Rewards from './pages/rewards';

function App() {
return (
	<Router>
	<Navbar />
	<Routes>
	<Route path='/' element={<Home/>} exact />
		<Route path='/home' element={<Home/>} exact />	
		<Route path='/mint' element={<Mint />} exact  />
		<Route path='/raffles' element={<Raffles />} exact  />
		<Route path='/rewards' element={<Rewards />} exact  />
	</Routes>
	</Router>
);
}

export default App;
