import React from "react";
import * as s from "../styles/globalStyles";
import ClubDay from "../images/ClubDay.png";
import test from "../images/test.png";





function Home() {
 
  window.location.replace('https://www.themidnighttokersclub.com/');

  return null;

}
export default Home
