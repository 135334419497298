const config = {

  
  

    "CONTRACT_ADDRESS": "0x8A5EDBF9743d3f0962B1e5AE2057d9F02D2b24DD",
    "RAFFLE_CONTRACT_ADDRESS": "0xCCcF435D16C7d50f25C617B7Fe64814Dd5a80619",
    "SCAN_LINK": "https://polygonscan.com/token/0x8A5EDBF9743d3f0962B1e5AE2057d9F02D2b24DD",
    "NETWORK": 
    {
      "NAME": "Polygon",
      "SYMBOL": "Matic",
      "ID": 137
    },
    "NFT_NAME": "Midnight Toker",
    "SYMBOL": "TMTC",
    "MAX_SUPPLY": 7777,
    "WEI_COST": 25000000000000000000,
    "RAFFLE_WEI_COST": 1500000000000000000,
    "DISPLAY_COST": 15,
    "TICKET_COST": 1.5,
    "GAS_LIMIT": 255000,
    "GAS_LIMITRaffles": 180000,
    "MAX_BASE_FEE": null,
    "MAX_PRIORITY_FEE": null,
    "MARKETPLACE": "Opeansea",
    "MARKETPLACE_LINK": "https://opensea.io/collection/themidnighttokersclub",
    "TRUE_HOME_LINK": "https://www.themidnighttokersclub.com/",
    "SHOW_BACKGROUND": true,
  
  
    "RAFFLE_CONTRACT_ADDRESS": "0xCCcF435D16C7d50f25C617B7Fe64814Dd5a80619",
    "RAFFLE_SCAN_LINK": "https://polygonscan.com/token/0xCCcF435D16C7d50f25C617B7Fe64814Dd5a80619",
    "RAFFLE_NAME": "Midnight Toker",
    "TICKET": "Ticket",
    "TICKET_WEI_COST": 1250000000000000000,
    "TICKET_DISPLAY_COST": 1.25,
    "MINT_PAGE": "Mint Page",
    "PROJECT_LINK": "https://themidnighttokersclub.com/index",
  
  
  


};

export { config };