const initialState = {
  loading: false,
  totalSupply: 0,
  tokenCount: 0,
  myTokers: 0,
  ownerOf: 0,
  farmingIDs: 0,
  balanceOf: 0,
  smartContract: 0,
  cost: 0,
  error: false,
  errorMsg: "",
  getMaxEntriesTMTC: 0,
  getMaxEntriesCollab: 0,
  getMaxEntriesTotal: 0,
  getNumTickets: 0,
  currentEntries: 0,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        tokenCount: action.payload.tokenCount,
        ownerOf: action.payload.ownerOf,
        balanceOf: action.payload.balanceOf,
        getPlayerTickets: action.payload.getPlayerTickets,
        getMaxEntriesTMTC: action.payload.getMaxEntriesTMTC,
        getMaxEntriesCollab: action.payload.getMaxEntriesCollab,
        getMaxEntriesTotal: action.payload.getMaxEntriesTotal,
        getWinners: action.payload.getWinners,
        numWinners: action.payload.numWinners,
        raffleLive: action.payload.raffleLives,
        getNumTickets: action.payload.getNumTickets,
        currentEntries: action.payload.currentEntries,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
