import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from 'reactjs-popup';
import { connect} from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import raffleDay from "../images/raffleDay.png";
//import raffleNight from "../images/raffleNight.png";
import raffleBackground from "../images/raffleBackground.png"
import raffleBackgroundMobile from "../images/raffleBackgroundMobile.png"
import liveBackground from "../images/liveBackground.png"
import liveBackgroundMobile from "../images/liveBackgroundMobile.png"
import { MdLogout, MdSailing, MdTheaters } from 'react-icons/md';
import { BsGlobe } from 'react-icons/bs';

import { config } from "../config";

export const StyledButton = styled.button`
  float: ${({ fl }) => (fl ? fl : "right")};
  padding: 10px;
  font-size: 11px;
  text-align: ${({ txa }) => (txa ? txa : "center")};
  margin-left: ${({ ml }) => (ml ? ml : "0px")};
  margin-right: ${({ mr }) => (mr ? mr : "0px")};
  border-radius: ${({ br }) => (br ? br : "50px")};
  border: none;
  background-color: ${({ bg }) => (bg ? bg : "#ffffff")};;
  padding: 10px;
  font-weight: bold;
  font-size:${({ fs }) => (fs ? fs : "0px")};
  color: #000000;
  width:  ${({ w }) => (w ? w : "100px")};
  cursor: ${({ crs }) => (crs ? crs : "")};
  z-index:44;
  position: "absolute";
  box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
    : active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
`;

export const InfoButton = styled.button`
  float: ${({ fl }) => (fl ? fl : "right")};
  padding: 10px;
  font-size: 10px;
  text-align: ${({ txa }) => (txa ? txa : "center")};
  margin-left: ${({ ml }) => (ml ? ml : "0px")};
  margin-right: ${({ mr }) => (mr ? mr : "0px")};
  border-radius: ${({ br }) => (br ? br : "50px")};
  border: none;
  background-color: ${({ bg }) => (bg ? bg : "#ffffff46")};;
  padding: 10px;
  font-weight: regular;
  font-size:${({ fs }) => (fs ? fs : "0px")};
  color: #000000;
  width:  ${({ w }) => (w ? w : "1000px")};
  cursor: ${({ crs }) => (crs ? crs : "")};
  z-index:44;
  position: "absolute";
  box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px-2px rgba(250, 250, 250, 0.3);
    : active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
`;

export const CountBox = styled.button`
padding: 10px;
border: 2px solid black;
border-radius: ${({ br }) => (br ? br : "50px")};
background-color: ${({ bg }) => (bg ? bg : "#ffffff")};;
margin-left: ${({ ml }) => (ml ? ml : "0px")};
font-weight: bold;
font-size:${({ fs }) => (fs ? fs : "0px")};
color: #000000;
width:  ${({ w }) => (w ? w : "45px")};
cursor: pointer;
}
`;

export const ResponsiveWrapper = styled.div`
display: contents;
flex: 0;
flex-direction: column;
justify-content: stretched;
align-items: stretched;
width: 100 %;
@media(min-width: 767px) {
  flex-direction: row;
}
`;

export const StyledImg = styled.img`
width: 200px;
height: 200px;
@media(min-width: 767px) {
  width: 350px;
  height: 350px;
}
transition: width 0.5s;
transition: height 0.5s;
`;




function Raffles() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);;
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    RAFFLE_CONTRACT_ADDRESS: "",
    RAFFLE_SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    RAFFLE_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    TICKET_WEI_COST: 0,
    TICKET_DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MINT_PAGE: "",
    PROJECT_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const [RAFFLE, SET_RAFFLE] = useState({
  PRIZE: "",
  LIVE: "",
  });
  
  const closeModal = () => {
    document.getElementById('root').style.filter = "brightness(100%)";
    setOpen(false);
  };

  const claimRaffle = (_amount) => {
    if (_amount <= 0) {
      toast.error('Select an amount to mint.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let cost = config.RAFFLE_WEI_COST;
    if (_amount > 4) 
    cost = 1000000000000000000;
    
    let gasLimit = config.GAS_LIMITRaffles;
    let totalCostWei = String(cost * _amount);
    let totalGasLimit = String(gasLimit * _amount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    toast.success(`Snaggin' your tickets`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    blockchain.smartContract.methods
      .enterRaffle(_amount)
      .send({
        gasLimit: String(totalGasLimit),
        to: config.RAFFLE_CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .once("error", (err) => {
        console.log(err);
        let errorMsg = 'Something went wrong please try again later.';
        if (err.hasOwnProperty("message")) {
          errorMsg = err.message;
        }
        if (err)
          toast.error(errorMsg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        setCount(0);
      })
      .then((receipt) => {
        toast.success('Successfully perchased your tickets', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(0);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimCollabRaffle = (_amount) => {
    if (_amount <= 0) {
      toast.error('Select an amount to mint.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let cost = config.RAFFLE_WEI_COST;
    if (_amount > 4) 
    cost = 1000000000000000000;
    let gasLimit = config.GAS_LIMITRaffles;
    let totalCostWei = String(cost * _amount);
    let totalGasLimit = String(gasLimit * _amount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    toast.success(`Snaggin' your tickets`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    blockchain.smartContract.methods
      .enterCollabRaffle(_amount)
      .send({
        gasLimit: String(totalGasLimit),
        to: config.RAFFLE_CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .once("error", (err) => {
        console.log(err);
        let errorMsg = 'Something went wrong please try again later.';
        if (err.hasOwnProperty("message")) {
          errorMsg = err.message;
        }
        if (err)
          toast.error(errorMsg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        setCount(0);
      })
      .then((receipt) => {
        toast.success('Successfully perchased your tickets', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(0);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);


  const arr = [data.getPlayerTickets];
  const tickets = arr.join(",");

  const winningTickets = [data.getWinners];
  const winners = winningTickets.join(",");
  
  const totalTokers = [data.tokenCount];
  const tokers = totalTokers.join(",")

  const addy = (ethereum.selectedAddress)
  const numWinners = (data.numWinners)
  const rafflePrize = (RAFFLE.PRIZE)
  const numTickets = (data.getNumTickets)
  const currentEntriesTotal = (data.currentEntriesTotal)

  console.log({tickets});
  console.log({winners});
  console.log({addy});
  console.log({numWinners});
  console.log({rafflePrize});
  console.log({numTickets});
  console.log({currentEntriesTotal});


  const getPrizes = async () => {
  const prizeResponse = await fetch("/config/rewards.json");

    const prizes = await prizeResponse.json();
    SET_RAFFLE(prizes);
  };

  useEffect(() => {
    getPrizes();
  }, []);


  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [blockchain.account]);



  useEffect(() => {
    if (blockchain.errorMsg !== "") {
      toast.error(blockchain.errorMsg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(connectFailed(""));
    }
    // eslint-disable-next-line 
  }, [blockchain.errorMsg]);
  // eslint-disable-next-line

  return (
    <s.Screen
      style={{
        backgroundImage: blockchain.account ?
          window.innerWidth > 600 ? `url(${liveBackground})` : `url(${liveBackgroundMobile})` //second one is mobile view
          :
          window.innerWidth > 600 ? `url(${raffleBackground})` : `url(${raffleBackgroundMobile})`
      }}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
      
        <ResponsiveWrapper d={"block"} flex={1} ai={"center"} >
          {
            !blockchain.account ?
              (
                <s.Container flex={1} ai={"end"} onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}>
                  
                  <StyledButton fs={window.innerWidth > 1500 ? "13px" : "15px"} crs="pointer">Connect</StyledButton>
                </s.Container>
               
              ) :
              (
                <>
                 

                 
                 <s.Container d={"inline"} flex={0} ai={"end"} pb={"5px"}>
                    <StyledButton fs={window.innerWidth > 1500 ? "13px" : "13px"} crs="pointer" bg={"pink"} br={"50px"} ml={"0px"} onClick={(e) => {
                      e.preventDefault();
                      window.location.reload();
                    }}><MdLogout style={{ fontSize: window.innerWidth > 1500 ? "14px" : "16px", float: "right" }} /></StyledButton>
                    <StyledButton fs={window.innerWidth > 1500 ? "13px" : "13px"} crs="pointer" w={window.innerWidth > 1500 ? "145px" : "145px"} br={"50px"} ml={"-35px"} mr={"-69px"} txa={"right"} onClick={(e) => {
                      e.preventDefault();
                      window.open(`https://polygonscan.com/address/${blockchain.account}`, '_blank');
                    }}>
                      {blockchain.account.slice(0, 6) + "...." + blockchain.account.slice(blockchain.account.length - 5)}
                    </StyledButton>
                  </s.Container>

                  <s.Container1 d={"center"} flex={0} ai={"center"}>
                    <StyledButton fs={window.innerWidth > 1500 ? "11px" : "12px"} w={window.innerWidth > 1500 ? "115px" : "145px"} ml={"10px"} bg={"lightGreen"}  >Tickets Owned: {data.currentEntries}</StyledButton>
                    <StyledButton fs={window.innerWidth > 1500 ? "11px" : "12px"} w={window.innerWidth > 1500 ? "115px" : "145px"} ml={"10px"} bg={"lightGreen"}  >Max Toker Tickets: {data.getMaxEntriesTMTC}</StyledButton>
                    <StyledButton fs={window.innerWidth > 1500 ? "11px" : "12px"} w={window.innerWidth > 1500 ? "115px" : "145px"} ml={"10px"} bg={"lightGreen"}  >Max Collab Tickets: {data.getMaxEntriesCollab}</StyledButton>
                    <StyledButton fs={window.innerWidth > 1500 ? "11px" : "12px"} w={window.innerWidth > 1500 ? "115px" : "145px"} ml={"10px"} bg={"lightGreen"}  >Total Tickets Available: {data.getMaxEntriesTotal}</StyledButton>
                  </s.Container1>

                    <s.Container d={"center"} flex={0} ai={"center"} pb={"5px"}>
                    </s.Container>
                      <s.SpacerXXL />
                    <s.Container2 fs={window.innerWidth > 1500 ? "13px" : "16px" }
                     crs="pointer" bg={"white"} br={"50px"} ml={"0px"} jc={"center"}
                     
                     //onclick here
                     >
                      <s.TextTitle>
                        Raffle Deets
                      </s.TextTitle>
                      <br />
                      TMTC Tickets Available: {data.getMaxEntriesTMTC}<br />
                      Collab Tickets Available: {data.getMaxEntriesCollab}<br />
                      You can buy a maximum of {data.getMaxEntriesTotal} tickets TOTAL <br />
                      <br />
                      Is Raffle Active? {RAFFLE.LIVE} <br />
                      <br />
                      <br />
                      A total of { data.getNumTickets} tickets have been claimed. <br />
                      You own { data.currentEntries} <br />
                      Your Tickets: { tickets} <br />
                      Winning Tickets: { winners} <br />
                      Total Winners: { data.numWinners} <br />
                      
                    
                      
                     


                      
                     
                    </s.Container2>
                    
                   
                  
                  <s.Container flex={1} onClick={() => {
                    document.getElementById('root').style.filter = "brightness(50%)";
                    setOpen(o => !o)
                  }}>
                    
                  </s.Container>
                  <Popup open={open} onClose={closeModal} position="center">
                    {close => (
                      <s.Container
                        flex={1}
                        d={"inline-block"}
                        jc={"center"}
                        ai={"center"}
                        style={{
                          opacity: 0.9,
                          backgroundColor: "#fff",
                          padding: 20,
                          paddingLeft: window.innerWidth > 600 ? 120 : 60,
                          paddingRight: window.innerWidth > 600 ? 120 : 60,
                          borderRadius: 20
                        }}
                      > 
                      
                                            
                      <CountBox fs={"12px"} w={"35px"} br={"18px"} ml={"5px"} crs={"pointer"} onClick={(e) => {
                          e.preventDefault();
                          if (count > 0) {
                            setCount(count - 1);
                          }
                        }}> - </CountBox>

                        <CountBox fs={"15px"} w={"46px"} br={"12px"} ml={"5px"}>{count}</CountBox>

                        <CountBox fs={"12px"} w={"35px"} br={"18px"} ml={"5px"} crs={"pointer"} onClick={(e) => {
                          e.preventDefault();
                          if (count < 25) {
                            setCount(count + 1);
                          }
                        }}> + </CountBox>
                        

                        <s.SpacerSmall />
                        <CountBox fs={"15px"} w={"110px"} bg={"lightgreen"} ml={"8px"} crs={"pointer"} onClick={(e) => {
                          e.preventDefault();
                          close();
                          claimRaffle(count);
                          getData();
                    //    window.location.reload();
                        }}>TokerTickets</CountBox>
                        <s.SpacerSmall />
                        <CountBox fs={"15px"} w={"110px"} bg={"lightblue"} ml={"8px"} crs={"pointer"} onClick={(e) => {
                          e.preventDefault();
                          close();
                          claimCollabRaffle(count);
                          getData();
                    //    window.location.reload();
                        }}>GojoTickets</CountBox>
                      </s.Container>
                    )}
                  </Popup>
                </>
              )
          }        </ResponsiveWrapper>
      </s.Container >
    </s.Screen >
  );
}

export default Raffles;