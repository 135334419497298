import React from 'react';
import {
Nav,
NavLink,
Bars,
NavMenu,
NavBtn,
NavBtnLink,
} from './NavbarElements';
import navBarLogo from '../../images/navBarLogo.png'


const Navbar = () => {
return (
	<>
	<Nav>
	

		<NavMenu
		onClick={(e) => window.location.reload()}
		>

		<img 
		src={navBarLogo}
		width="70"
		height="70"
		align="left"
		padding="40"
		 /> 

		<NavLink to='/home' activeStyle
		//onClick={(e) => 
		//window.open(config.TRUE_HOME_LINK)}
		
		onClick={(e) =>
		window.location.replace('https://www.themidnighttokersclub.com/')}
		>
			Home
		</NavLink>

		<NavLink to='/mint' activeStyle>
			Mint
		</NavLink>

		<NavLink to='/raffles' activeStyle>
			Raffles
		</NavLink>

		<NavLink to='/rewards' activeStyle>
			Rewards
		</NavLink>



		{/* Second Nav */}
		{/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
		</NavMenu>

	</Nav>
	</>
);
};

export default Navbar;
