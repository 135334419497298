// log
import store from "../store";
import { config } from "../../config";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const tokenID = (config.farmingIDs)

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

        let tokenCount = await store
        .getState()
        .blockchain.smartContract.methods.tokenCount()
        .call();

       // let ownerOf = await store 
       // .getState()
       // .blockchain.smartContract.methods.ownerOf()
       // .call();
    

      //  let balanceOf = await store
      //  .getState()
       // .blockchain.smartContract.methods.balanceOf(ethereum.selectedAddress)
      //  .call();

        let getPlayerTickets = await store
        .getState()
        .blockchain.raffleContract.methods.getPlayerTickets(ethereum.selectedAddress)
        .call();

        let getMaxEntriesTMTC = await store
        .getState()
        .blockchain.raffleContract.methods.getMaxEntriesTMTC(ethereum.selectedAddress)
        .call();

        let getMaxEntriesCollab = await store
        .getState()
        .blockchain.raffleContract.methods.getMaxEntriesCollab(ethereum.selectedAddress)
        .call();

        let getMaxEntriesTotal = await store
        .getState()
        .blockchain.raffleContract.methods.getMaxEntriesTotal(ethereum.selectedAddress)
        .call();

      let getWinners = await store
        .getState()
        .blockchain.raffleContract.methods.getWinners()
        .call();

        let numWinners = await store
        .getState()
        .blockchain.raffleContract.methods.numWinners()
        .call();

        let raffleLive = await store
        .getState()
        .blockchain.raffleContract.methods.raffleLive()
        .call();

        let getNumTickets = await store
        .getState()
        .blockchain.raffleContract.methods.getNumTickets()
        .call();

        let currentEntries = await store
        .getState()
        .blockchain.raffleContract.methods.currentEntries(ethereum.selectedAddress)
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          tokenCount,
         // ownerOf,
         // balanceOf,
          getPlayerTickets,
          getWinners,
          numWinners,
          getMaxEntriesTMTC,
          getMaxEntriesCollab,
          getMaxEntriesTotal, 
          raffleLive,
          getNumTickets,
          currentEntries,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
